export default [
  {
    title: "profile.edit",
    icon: "mdi-pencil",
    router: "edit-profile",
    key: null
  },
  // {
  //     title: "profile.interest_zone",
  //     icon: "mdi-map-marker",
  //     router: "",
  //     key: "areaOfInterest"
  // },
  {
    title: "profile.update_avatar",
    icon: "mdi-account-circle-outline",
    router: "",
    key: "avatar"
  },
  {
    title: "profile.update_cv",
    icon: "mdi-text-box-outline",
    router: "",
    key: "cv"
  },
  {
    title: "profile.update_video",
    icon: "mdi-play-circle",
    router: "",
    key: "video"
  },
  {
    title: "profile.update_other_files",
    icon: "mdi-file-multiple",
    router: "",
    key: "other_files"
  }
];

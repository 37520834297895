<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="400"
    transition="dialog-bottom-transition"
  >
    <v-container class="white rounded-lg">
      <v-row cols="12" sm="6" md="4" justify="end">
        <v-icon
          class="ma-0 pa-0 mt-2 mr-2"
          color="primary"
          @click="$emit('close')"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="align-center ma-0 pa-0 mt-6" justify="center">
        <p class="text-h6 text-center font-weight-bold secondary--text">
          {{ $t("video.videocv") }}
        </p>
      </v-row>
      <v-row class="text-center align-center ma-0 pa-0 my-2" justify="center">
        <p class="text font-weight-bold">
          {{ $t("video.videocv_description") }}
        </p>
      </v-row>
      <v-row class="secondary--text ml-2 font-size-bold" style="font-size: 16px"
        >{{ $t("video.remember") }}
      </v-row>
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="12" md="10" class="pa-0 mt-6 ml-2 ml-sm-0">
          <ul style="">
            <li class="grey--text pr-4">
              <span class="text text-weight-bold">{{
                $t("video.videocv_data_1")
              }}</span>
            </li>
            <li class="grey--text pr-4">
              <span class="text text-weight-bold">{{
                $t("video.videocv_data_2")
              }}</span>
            </li>
            <li class="grey--text pr-4">
              <span class="text text-weight-bold">{{
                $t("video.videocv_data_3")
              }}</span>
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" class="pr-0 text-center"
          ><v-icon color="secondary" size="20">mdi-information</v-icon></v-col
        >
        <v-col cols="11" class="justify-center">
          <a
            class="secondary--text font-weight-medium"
            style="text-decoration: underline; font-size: 12px"
            @click="goToFaqs"
            >{{ $t("video.faqs") }}
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pa-0 mt-2 text-center"
          style="margin-bottom: 30px"
        >
          <div>
            <v-btn
              color="secondary"
              class=""
              style="font-size: 16px; text-transform: none"
              large
              rounded
              @click="!isMobileDevice ? openRecordingVideo() : addVideo()"
            >
              {{ $t("video.start_record") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center" v-if="recordingVideo">
        <VideoJSRecord ref="video" @getVideo="getVideo" />
      </div>
      <v-row class="pa-0 ma-0" justify="center">
        <v-col cols="10" sm="8" md="8" class="mt-1 pa-0 ma-0">
          <v-file-input
            small-chips
            :class="video != null ? '' : 'd-none'"
            id="video"
            clear-icon="mdi-delete"
            @click:clear="resetVideo()"
            prepend-icon=""
            :label="$tc('video.title', 1)"
            truncate-length="15"
            v-model="video"
            capture="user"
            accept="video/*"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-col
        v-if="video != null"
        cols="12"
        class="pa-0 mt-2 text-center"
        style="margin-bottom: 30px"
      >
        <div>
          <v-btn
            color="transparent"
            large
            rounded
            outlined
            :loading="loadingBtn"
            class="customButton primary--text"
            @click="validateFile(video)"
          >
            {{ $t("video.edit") }}
          </v-btn>
        </div>
      </v-col>
    </v-container>
  </v-dialog>
</template>
<script>
import VideoJSRecord from "@/components/VideoJSRecord.vue";
import CandidateService from "@/services/CandidateService.js";
import getBlobDuration from "get-blob-duration";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["dialog"],
  components: {
    VideoJSRecord,
  },
  data() {
    return {
      service: null,
      video: null,
      loadingBtn: false,
      recordingVideo: false,
    };
  },
  computed: {
    ...mapGetters({ childId: "user/childId" }),
    isMobileDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    goToFaqs() {
      let routeData = this.$router.resolve({ name: "faqs" });
      window.open(routeData.href, "_blank");
    },
    addVideo() {
      document.getElementById("video").click();
    },
    openRecordingVideo() {
      this.recordingVideo = true;
    },
    async changeData() {
      this.enableLoading();

      try {
        this.loadingBtn = true;
        await CandidateService.uploadVideo(this.childId, this.video);
        this.notifySucces();
        this.modalAction();
        this.timeOut();
      } catch (error) {
        this.notifyError();
      } finally {
        this.loadingBtn = false;
        this.disableLoading();
      }
    },
    modalAction() {
      this.$emit("close");
    },
    resetVideo() {
      this.$refs.video.resetVideo();
    },
    getVideo(data) {
      document.getElementById("video").disabled = true;
      this.video = data;
    },
    timeOut() {
      setTimeout(function () {
        location.reload();
      }, 500);
    },
    validateFile(file) {
      getBlobDuration(file).then(
        function (duration) {
          if (duration < 50) {
            this.notifyErrorDuration();
            return;
          }
          this.changeData();
        }.bind(this)
      );
    },
    notifyErrorDuration() {
      this.$notify({
        title: this.$i18n.t("notification.video_duration_error"),
        type: "error",
      });
    },
    notifyError() {
      this.$notify({
        text: this.$i18n.t("notification.change_video_error"),
        type: "error",
      });
    },
    notifySucces() {
      this.$notify({
        text: this.$i18n.t("notification.change_video"),
        type: "success",
      });
    },
  },
};
</script>
<style scoped>
.customButton {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}
.text {
  font-size: 12px;
  color: grey;
}
</style>

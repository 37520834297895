<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="400"
    v-model="modal"
    @click:outside="$emit('closeImageModal')"
  >
    <v-row justify="center" class="ma-0">
      <v-img
        alt="Image"
        class="shrink"
        contain
        :src="src"
        transition="scale-transition"
      />
    </v-row>
  </v-dialog>
</template>
<script>
export default {
  props: ["showImageModal", "src"],
  data() {
    return {
      modal: null,
    };
  },
  mounted() {
    this.modal = this.showImageModal;
  },
  watch: {
    showImageModal: function () {
      this.modal = this.showImageModal;
    },
  },
  methods: {
    modalAction() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
</style>

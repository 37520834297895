<template>
  <v-dialog
    v-model="openModal"
    max-width="450"
    transition="dialog-bottom-transition"
    @click:outside="$emit('closeModal')"
  >
    <v-container fluid>
      <pdf-viewer
        v-if="pdfFile != null"
        @closePdfViewer="closePdfViewer"
        :showPdfViewer.sync="pdfDialog"
        :pdf-path.sync="pdfFile"
      />
      <image-modal
        @closeImageModal="closeImageModal"
        :showImageModal.sync="imageModal"
        :src="imageFile"
      />
      <!-- <v-row justify="end">
        <v-icon
          class="ma-0 pa-0 mt-2 mr-2 mb-4"
          color="primary"
          @click="$emit('closeModal')"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="align-center ma-0 pa-0" justify="center">
        <span class="primary--text">{{$t('other_files.preview')}}</span>
      </v-row>
      <v-row
        class="secondary--text ma-0"
        v-for="(file, index) in otherFiles"
        :key="index"
      >
        <v-col
          sm="2"
          style="cursor: pointer"
          class="links"
          @click="showFiles(file.file)"
        >
          {{ getName(file.file) }}
        </v-col>
      </v-row> -->
    </v-container>
  </v-dialog>
</template>
<script>
import PdfViewer from "@/components/modals/PdfViewer.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import { mapGetters } from "vuex";
export default {
  props: ["openModal"],
  components: {
    PdfViewer,
    ImageModal,
  },
  data() {
    return {
      pdfDialog: false,
      pdfFile: null,
      imageModal: false,
      imageFile: null,
      // docFile: null,
    };
  },
  computed: {
    ...mapGetters({ otherFiles: "candidate/otherFiles" }),
  },
  methods: {
    getExtension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    getName(url) {
      return url.split(/[#?]/)[0].split("/").pop().trim();
    },
    isPdf(extension) {
      return extension.match(/(pdf)$/i);
    },
    isImage(extension) {
      return extension.match(/(jpg|jpeg|png|gif)$/i);
    },
    // isDoc(extension) {
    //   return extension.match(/(doc|docx|xlxx)$/i);
    // },
    showFiles(url) {
      var extension = this.getExtension(url);
      if (this.isPdf(extension)) {
        this.pdfFile = url;
        this.pdfDialog = true;
      } else if (this.isImage(extension)) {
        this.imageFile = url;
        this.imageModal = true;
      }
    },
    closePdfViewer() {
      this.pdfDialog = !this.pdfDialog;
      this.$emit("closeModal");
    },
    async closeImageModal() {
      this.imageModal = false;
      this.$emit("closeModal");
    },
  },
  watch: {
    openModal: function () {
      if (this.openModal) {
        this.showFiles(this.otherFiles[0].file);
      }
    },
  },
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.customButton {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}
.links:hover {
  text-decoration: underline;
}
iframe {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>

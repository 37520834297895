<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="showPdfViewer"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="700"
      @click:outside="$emit('closePdfViewer')"
    >
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t("pdf_viewer.pdf_view") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-flex class="text-right">
          <v-btn icon @click="forceFileDownload(pdfPath)" v-if="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="$emit('closePdfViewer')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-toolbar>
      <v-card>
        <pdf
          :src="src"
          :page="currentPage"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
          :style="{
            width: $vuetify.breakpoint.smAndDown ? '100%' : '85%',
            height: '80%',
          }"
        >
          <template slot="loading">
            {{ $t("pdf_viewer.loading") }}
          </template>
        </pdf>

        <v-layout row class="ma-0">
          <v-flex xs2 class="">
            <v-btn
              class="primary"
              block
              :disabled="noPrevPage"
              @click="prevPage"
              >{{
                $vuetify.breakpoint.smAndDown
                  ? $t("pdf_viewer.sm_prev")
                  : $t("pdf_viewer.prev")
              }}</v-btn
            >
          </v-flex>

          <v-flex xs8 mt-3 class="text-center"
            >{{ currentPage }} / {{ pageCount }}</v-flex
          >

          <v-flex xs2>
            <v-btn
              class="primary"
              block
              :disabled="noNextPage"
              @click="nextPage"
              >{{
                $vuetify.breakpoint.smAndDown
                  ? $t("pdf_viewer.sm_next")
                  : $t("pdf_viewer.next")
              }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import pdf from "vue-pdf";
export default {
  name: "PdfViewer",
  props: ["pdfPath", "showPdfViewer", "download"],
  components: {
    pdf,
  },
  mounted() {
    this.src = pdf.createLoadingTask(this.$props.pdfPath);
  },
  data() {
    return this.initialData();
  },
  computed: {
    noPrevPage() {
      return this.currentPage <= 1;
    },
    noNextPage() {
      return this.currentPage === this.pageCount;
    },
  },
  methods: {
    initialData() {
      return {
        dialog: true,
        currentPage: 1,
        pageCount: 0,
        src: null,
      };
    },
    prevPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
    forceFileDownload(url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.getFileName(url));
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    },
    getFileName(url) {
      var title = url.split(/[#?]/)[0].split("/").pop().trim();
      return title.split(/[#?]/)[0].split(".")[0].trim();
    },
  },
  beforeDestroy() {
    Object.assign(this.$data, this.initialData());
  },
};
</script>

<template>
  <v-container v-if="mounted">
    <change-modal
      v-if="changeFiles"
      :dialog="changeFiles"
      :type="modalType"
      @close="closeFiles"
    />
    <record-video
      v-if="recordVideo"
      :dialog="recordVideo"
      @close="toggleRecordVideo"
    />
    <other-files
      v-if="otherFiles.length > 0"
      @closeModal="toggleModal"
      :openModal.sync="showotherFiles"
    />
    <div>
      <pdf-viewer
        v-if="candidate.curriculum"
        @closePdfViewer="closePdfViewer"
        :showPdfViewer.sync="pdfDialog"
        :pdf-path="candidate.curriculum"
      />
      <video-player
        v-if="candidate.video"
        @closeVideoPlayer="toggleVideoPlayer"
        :showVideoPlayer.sync="videoPlayer"
        :src="candidate.video"
      />
    </div>
    <v-row justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="ml-2">
          <v-list-item-avatar tile size="80" v-if="candidate.avatar">
            <v-img
              alt="user avatar"
              class="shrink"
              contain
              :src="candidate.avatar"
              transition="scale-transition"
          /></v-list-item-avatar>
          <v-list-item-avatar tile size="80" v-if="!candidate.avatar">
            <v-img
              alt="user avatar"
              class="shrink"
              contain
              src="@/assets/apotalent/profile.png"
              transition="scale-transition"
              style="cursor: pointer"
              @click="handleEvent('avatar')"
          /></v-list-item-avatar>
          <v-list-item-content>
            <v-col cols="10" class="pa-0 ma-0"
              ><div class="text-subtitle-1 primary--text">
                {{ candidate.full_name }}
              </div>
              <v-list-item-subtitle
                class="secondary--text text-caption font-weight-light"
                >{{ candidate.user.email }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                class="secondary--text text-caption font-weight-light"
                >{{ candidate.phone_number }}</v-list-item-subtitle
              >
            </v-col>
            <v-col cols="2" class="mb-6 pa-0 ma-0 text-end">
              <v-menu left bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-dots-vertical </v-icon>
                </template>
                <!-- Profile dropdown list -->
                <v-list class="rounded-lg">
                  <v-list-item @click="() => {}" class="justify-end">
                    <v-icon color="primary">mdi-close</v-icon>
                  </v-list-item>
                  <v-row
                    dense
                    v-for="(option, index) in profileOptions"
                    :key="index"
                  >
                    <v-list-item
                      :to="option.router"
                      @click="option.key != null && handleEvent(option.key)"
                    >
                      <v-list-item-title
                        class="font-weight-light secondary--text"
                      >
                        <v-icon color="primary">{{ option.icon }}</v-icon>
                        {{ $tc(option.title, 2) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-row>
                </v-list>
              </v-menu>
            </v-col>

            <div></div>

            <div class="mt-3">
              <v-btn
                class="pa-0 mx-1 mb-1"
                :color="!candidate.curriculum ? 'error' : 'success'"
                @click="
                  !candidate.curriculum ? handleEvent('cv') : (pdfDialog = true)
                "
              >
                <img src="@/assets/apotalent/cv.svg" />
              </v-btn>
              <v-btn
                class="pa-0 mx-1 mb-1"
                :color="!candidate.video ? 'error' : 'success'"
                @click="
                  !candidate.video ? handleEvent('video') : toggleVideoPlayer()
                "
              >
                <v-icon> mdi-play-circle </v-icon>
              </v-btn>
              <v-btn
                :color="!otherFiles.length > 0 ? 'error' : 'success'"
                class="pa-0 mx-2 mb-1"
                @click="
                  !otherFiles.length > 0
                    ? handleEvent('other_files')
                    : (showotherFiles = true)
                "
              >
                <v-icon> mdi-folder-multiple </v-icon>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item></v-col
      ></v-row
    >
    <!-- <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("candidate.studies") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="pa-0 ml-4" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0">
        <v-list-item class="ml-2 ma-0 pa-0">
          <v-list-item-avatar tile size="30" color="grey"></v-list-item-avatar>
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="mb-1 text-caption primary--text font-weight-bold"
            >
              {{ candidate.title.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row> -->
    <!-- <v-row class="pa-0 ml-4" justify-sm="center">
      <v-col cols="12" md="6" sm="8" class="pa-0">
        <div class="pl-2 pa-0 text-caption secondary--text font-weight-light">
          sep 2019 - actualidad: 1 año y 2 meses
        </div>
        <v-list-item class="ml-2 ma-0 pa-0">
          <v-list-item-avatar tile size="30" color="grey"><    this.phone_number = phone
    },/v-list-item-avatar>
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="mb-1 text-caption primary--text font-weight-bold"
            >
              Técnico de farmacia Madrid
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-caption font-weight-light secondary--text"
              >Escuela Pharm</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-col> 
    </v-row>-->
    <!-- <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("candidate.last_position") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row
      class="pa-0 ml-4"
      justify-sm="center"
      v-for="(experience, index) in candidate.experiences"
      :key="index"
    >
      <v-col cols="12" md="5" sm="8" class="pa-0">
        <div class="pl-2 pa-0 text-caption secondary--text font-weight-light">
          sep 2019 - actualidad: 1 año y 2 meses
        </div>
        <v-list-item class="ml-2 ma-0 pa-0">
          <v-list-item-avatar tile size="30" color="grey"></v-list-item-avatar>
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="mb-1 text-caption primary--text font-weight-bold"
            >
              {{ experience.title.name }}
            </v-list-item-title>
             <v-list-item-subtitle
              class="text-caption font-weight-light secondary--text"
              >{{ experience.pharmacy_name }}</v-list-item-subtitle
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row> -->
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("register.personal_data") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.name") + ":" }}</span
              >
              <span class="primary--text font opacity">
                {{ candidate.full_name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.email") + ":" }}
              </span>
              <span class="primary--text font opacity">
                {{ candidate.user.email }}
              </span>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.phone_number") + ":" }}
              </span>
              <span class="primary--text font opacity">
                {{ candidate.phone_number }}
              </span>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.birthday") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ candidate.birthday_date }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.address") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ candidate.address.formatted }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="my-1"
              v-if="candidate.languages.length > 0"
            >
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.languages") + ":" }}
              </span>
              <span
                v-for="(language, index) in candidate.languages"
                :key="index"
                class="primary--text font opacity"
                >{{ index == 0 ? "" : ", " }}{{ language.language.name | trim }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <div v-if="hasProfesionalData()">
      <v-row justify-sm="center">
        <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
          <v-list-item class="primary--text ml-3">
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                {{ $t("candidate.professional_data") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row class="ma-0" justify-sm="center">
        <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
          <v-divider class="mx-4"></v-divider>
        </v-col>
      </v-row>
      <v-row class="ma-0" justify-sm="center">
        <v-col class="pa-0" cols="12" sm="8" md="5">
          <v-list-item class="font-weight-light tertiary--text">
            <v-list-item-content class="ml-2">
              <v-list-item-subtitle
                class="my-1"
                v-if="candidate.profile != null && candidate.profile.license"
              >
                <span class="text-weight-bold primary--text font"
                  >{{ $t("field.license") + ":" }}
                </span>
                <span class="primary--text font opacity"
                  >{{ candidate.profile.license }}
                </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="candidate.experience_fields.length > 0"
                class="my-1"
                ><span class="text-weight-bold primary--text font">{{
                  $t("exeprience_areas.title") + ":"
                }}</span>
                <ul style="list-style-type: none">
                  <li
                    v-for="(field, index) in candidate.experience_fields"
                    :key="index"
                    class="primary--text"
                  >
                    <span class="primary--text font opacity">
                      {{ "" + field.name + "." }}
                    </span>
                  </li>
                </ul>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="candidate.expertises.length > 0"
                class="my-1"
                ><span class="text-weight-bold primary--text font">{{
                  $tc("specialization.title", 2) + ":"
                }}</span>
                <ul style="list-style-type: none">
                  <li
                    v-for="(expertise, index) in candidate.expertises"
                    :key="index"
                    class="primary--text"
                  >
                    <span class="primary--text font opacity">
                      {{ "" + expertise.name + "." }}
                    </span>
                  </li>
                </ul>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="candidate.pharmacy_experience"
                class="my-1"
              >
                <span class="text-weight-bold primary--text font">
                  {{ $t("field.pharmacy_experience") }}</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </div>
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("candidate.studies") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.title") + ":" }}</span
              >
              <span class="primary--text font opacity">
                {{ candidate.title.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1" v-if="candidate.profile != null">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.university") + ":" }}</span
              >
              <span class="primary--text font opacity">
                {{ candidate.profile.university.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="my-1"
              v-if="
                candidate.profile != null &&
                candidate.profile.license_state != null
              "
            >
              <span class="text-weight-bold primary--text font">
                {{ $t("field.license_state") + ":" }}</span
              >
              <span class="primary--text font opacity" v-if="candidate.profile.license_state">
                {{ candidate.profile.license_state.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="candidate.educations.length > 0"
              class="my-1"
              ><span class="text-weight-bold primary--text font">{{
                $t("field.other_title") + ":"
              }}</span>
              <span class="primary--text font opacity">
                {{ candidate.educations | titleList }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="candidate.computer_programs.length > 0"
              class="mt-1 mb-4 pb-4"
              ><span class="text-weight-bold primary--text font">{{
                $t("register.computer_programs") + ":"
              }}</span>
              <ul style="list-style-type: none">
                <li
                  v-for="(cprogram, index) in candidate.computer_programs"
                  :key="index"
                  class="primary--text"
                >
                  <span class="primary--text font opacity">
                    {{ "" + cprogram.name + "." }}
                  </span>
                </li>
              </ul>
            </v-list-item-subtitle>
            
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Menu options
import profileOptions from "@/components/profiles/CandidateProfileOptions.js";

// Edit data modal
import ChangeModal from "@/components/modals/ChangeModal.vue";
// Modals
import PdfViewer from "../../components/modals/PdfViewer.vue";
import OtherFiles from "../../components/modals/OtherFiles.vue";
import VideoPlayer from "@/components/modals/VideoPlayer.vue";
import RecordVideo from "@/components/modals/RecordVideo.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ChangeModal,
    PdfViewer,
    OtherFiles,
    VideoPlayer,
    RecordVideo,
  },
  data() {
    return {
      profileOptions: profileOptions,
      modalType: null,
      changeFiles: false,
      recordVideo: false,
      showotherFiles: false,
      haveOtherFiles: false,
      videoPlayer: false,
      pdfDialog: false,
      mounted: false,
    };
  },
  async created() {
    this.enableLoading();
    await this.getCandidateById(this.childId);
    await this.getOtherFiles(this.childId);
    this.mounted = true;
    this.disableLoading();
  },
  computed: {
    ...mapGetters({ candidate: "candidate/candidate" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ otherFiles: "candidate/otherFiles" }),
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ getOtherFiles: "candidate/getOtherFiles" }),
    ...mapActions({ getCandidateById: "candidate/getCandidateById" }),

    handleEvent(opt) {
      if (opt == "video") {
        this.recordVideo = true;
      } else {
        this.modalType = opt;
        this.changeFiles = true;
      }
    },
    closeFiles() {
      this.changeFiles = !this.changeFiles;
    },
    toggleRecordVideo() {
      this.recordVideo = !this.recordVideo;
    },
    closePdfViewer() {
      this.pdfDialog = !this.pdfDialog;
    },
    toggleVideoPlayer() {
      this.videoPlayer = !this.videoPlayer;
    },
    toggleModal() {
      this.showotherFiles = !this.showotherFiles;
    },
    hasProfesionalData() {
      return !(
        this.candidate.experience_fields.length == 0 &&
        this.candidate.expertises.length == 0 &&
        this.candidate.pharmacy_experience != true
      );
    },
  },
};
</script>
<style scoped>
.font {
  font-size: 13px;
}
.opacity {
  opacity: 0.6;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}
.v-divider {
  border-color: #718f94 !important;
}
.v-list-item__subtitle {
  white-space: normal !important;
}
</style>
